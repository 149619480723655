import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Divider } from "@mui/material";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useWeb3Provider } from "../hooks/Web3Provider";
import ConnectButton from "./ConnectButton";
import DisconnectButton from "./DisconnectButton";
import OpenEtherscan from "./OpenEtherscan";

const WalletInfo = () => {
  const navigate = useNavigate();
  const { signer, connectedWalletAddress } = useWeb3Provider();
  const [thresholdText, setThresholdText] = useState();

  const fetchThresholds = async () => {
    const result = await API.graphql({
      query: queries.listSaunoaNftThresholds,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        limit: 99999999,
      },
    });
    console.log(result.data);
    const thresholds = Object.keys(result.data.listSaunoaNftThresholds.items)
      .map(function (key) {
        return result.data.listSaunoaNftThresholds.items[key];
      })
      .sort(function (a, b) {
        return a.threshold < b.threshold ? -1 : 1;
      });
    let thresholdText = "";
    for (const threshold of thresholds) {
      thresholdText += `${threshold.threshold} / `;
    }
    setThresholdText(thresholdText);
  };

  const editThresholdHandler = () => {
    navigate(`/threshold/edit`);
  };

  useEffect(() => {
    fetchThresholds();
  }, []);

  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Box
            sx={{
              width: "100%",
              border: 1,
              p: 3,
              borderColor: "rgba(0, 0, 0, 0.26)",
              borderRadius: 3,
              mb: 3,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                  管理者情報
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  接続中Wallet
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {connectedWalletAddress ? connectedWalletAddress : "未接続"}
                </Box>
              </Grid>
              <Grid item xs={3}>
                {signer ? (
                  <>
                    <DisconnectButton />
                  </>
                ) : (
                  <>
                    <ConnectButton />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  会員券コントラクト
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <OpenEtherscan
                  contract={process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  メダルコントラクト
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {process.env.REACT_APP_MEDAL_NFT_CONTRACT}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <OpenEtherscan
                  contract={process.env.REACT_APP_MEDAL_NFT_CONTRACT}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                  メダル獲得閾値
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>{thresholdText}</Box>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{
                    width: 180,
                    p: 1,
                    border: 2,
                    borderRadius: 5,
                    "&:hover": {
                      border: 2,
                    },
                  }}
                  onClick={editThresholdHandler}
                >
                  編集する
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WalletInfo;
