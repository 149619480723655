import { Box, Grid, Divider } from "@mui/material";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ja from "date-fns/locale/ja";
import VisitHistoryDeleteButton from "./VisitHistoryDeleteButton";

const VisitedDate = ({ visitId, createdAt, fetchCustomer }) => {
  const formatDate = (date) => {
    if (!date) {
      return;
    }
    return format(
      utcToZonedTime(parseISO(date), "Asia/Tokyo"),
      "yyyy/MM/dd (E) HH:mm",
      { locale: ja }
    );
  };
  return (
    <>
      <Grid item xs={6}>
        <Box sx={{ textAlign: "center" }}>{formatDate(createdAt)}</Box>
      </Grid>
      <Grid item xs={6}>
        <VisitHistoryDeleteButton
          visitId={visitId}
          fetchCustomer={fetchCustomer}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 1 }} />
      </Grid>
    </>
  );
};

export default VisitedDate;
