import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  Box,
  Button,
  Grid,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material";
import Threshold from "../components/Threshold";

const EditThreshold = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [thresholds, setThresholds] = useState();
  const [inputThreshold, setInputThreshold] = useState();

  const fetchThresholds = async () => {
    const result = await API.graphql({
      query: queries.listSaunoaNftThresholds,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        limit: 99999999,
      },
    });
    console.log(result.data);
    const thresholds = Object.keys(result.data.listSaunoaNftThresholds.items)
      .map(function (key) {
        return result.data.listSaunoaNftThresholds.items[key];
      })
      .sort(function (a, b) {
        return a.threshold < b.threshold ? -1 : 1;
      });
    setThresholds(thresholds);
    console.log(thresholds);
  };

  const deleteThreshold = async (threshold) => {
    await API.graphql({
      query: mutations.deleteSaunoaNftThreshold,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          threshold: threshold,
        },
      },
    });
    await fetchThresholds();
  };

  const addThreshold = async (threshold) => {
    await API.graphql({
      query: mutations.createSaunoaNftThreshold,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          threshold: threshold,
        },
      },
    });
    await fetchThresholds();
  };

  const deleteThresholdHandler = async (threshold) => {
    setIsLoading(true);
    await deleteThreshold(threshold);
    setIsLoading(false);
  };
  const addThresholdHandler = async () => {
    setIsLoading(true);
    await addThreshold(inputThreshold);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchThresholds();
  }, []);

  return (
    <>
      <Box sx={{ m: 5 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Box
              sx={{
                border: 1,
                p: 3,
                borderColor: "rgba(0, 0, 0, 0.26)",
                borderRadius: 3,
                m: 5,
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                    メダル獲得閾値
                  </Box>
                </Grid>
                {thresholds?.map((threshold, index) => (
                  <Threshold
                    threshold={threshold.threshold}
                    fetchThresholds={fetchThresholds}
                  />
                ))}
                <Grid item xs={6}>
                  <Box sx={{ textAlign: "center" }}>
                    <TextField
                      variant="outlined"
                      label="閾値"
                      size="small"
                      sx={{ mx: 2 }}
                      onChange={(e) => setInputThreshold(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="text"
                    sx={{
                      display: "block",
                      margin: "auto",
                    }}
                    onClick={() => addThresholdHandler()}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "red" }} />
                    ) : (
                      "追加する"
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ m: 1 }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditThreshold;
