import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";
import VisitedDate from "../components/VisitedDate";
import MembershipNft from "../components/MembershipNft";
import MedalNft from "../components/MedalNft";
import CustomerDetail from "../components/CustomerDetail";
import SendMembershipButton from "../components/SendMembershipButton";

const Detail = () => {
  const { walletAddress } = useParams();
  const { signer } = useWeb3Provider();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [status, setStatus] = useState();
  const [visits, setVisits] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [membershipNfts, setMembershipNfts] = useState([]);
  const [medalNfts, setMedalNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMembershipNft = async () => {
    setIsLoading(true);
    try {
      const res = await API.get("saunoaAdminRestApi", "/membership", {
        queryStringParameters: {
          walletAddress,
        },
      });
      console.log(res);
      if (res.nft) {
        setMembershipNfts(res.nft);
      } else {
        setMembershipNfts([]);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const fetchMedalNft = async () => {
    setIsLoading(true);
    try {
      const res = await API.get("saunoaAdminRestApi", "/medals", {
        queryStringParameters: {
          walletAddress,
        },
      });
      console.log(res);
      if (res.nft) {
        console.log(res.nft);
        setMedalNfts(res.nft);
      } else {
        setMedalNfts([]);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const fetchCustomer = async () => {
    console.log(walletAddress);
    const result = await API.graphql({
      query: queries.getCustomer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        walletAddress: walletAddress,
      },
    });
    console.log(result.data);
    setName(result.data.getCustomer.name);
    setEmail(result.data.getCustomer.email);
    setStatus(result.data.getCustomer.status);
    setVisits(
      Object.keys(result.data.getCustomer.visits.items)
        .map(function (key) {
          return result.data.getCustomer.visits.items[key];
        })
        .sort(function (a, b) {
          return a.createdAt < b.createdAt ? -1 : 1;
        })
    );
    Object.keys(result.data.getCustomer.visits.items)
      .map(function (key) {
        return result.data.getCustomer.visits.items[key];
      })
      .sort(function (a, b) {
        return a.createdAt < b.createdAt ? -1 : 1;
      });
    setCreatedAt(result.data.getCustomer.createdAt);
    setUpdatedAt(result.data.getCustomer.updatedAt);
  };

  const mintNftMedal = () => {
    navigate(`/medal/${walletAddress}`);
  };

  useEffect(() => {
    fetchCustomer();
    fetchMembershipNft();
    fetchMedalNft();
  }, []);

  return (
    <>
      <Box sx={{ m: 5 }}>
        <CustomerDetail
          walletAddress={walletAddress}
          name={name}
          email={email}
          status={status}
          visits={visits}
          createdAt={createdAt}
          updatedAt={updatedAt}
          fetchCustomer={fetchCustomer}
        />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Box
              sx={{
                border: 1,
                p: 3,
                borderColor: "rgba(0, 0, 0, 0.26)",
                borderRadius: 3,
                m: 5,
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                    来店履歴
                  </Box>
                </Grid>
                {visits?.map((visit, index) => (
                  <VisitedDate
                    visitId={visit.id}
                    createdAt={visit.createdAt}
                    fetchCustomer={fetchCustomer}
                    key={visit.id}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {!isLoading && (
              <>
                <Box
                  sx={{
                    border: 1,
                    p: 3,
                    borderColor: "rgba(0, 0, 0, 0.26)",
                    borderRadius: 3,
                    m: 5,
                  }}
                >
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <Box
                        sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}
                      >
                        NFT会員券
                      </Box>
                    </Grid>
                    {membershipNfts?.map((membershipNft, index) => (
                      <MembershipNft
                        membershipNft={membershipNft}
                        walletAddress={walletAddress}
                        fetchCustomer={fetchCustomer}
                        fetchMembershipNft={fetchMembershipNft}
                        status={status}
                        key={`membership-${membershipNft.contract_address}-${membershipNft.token_id}`}
                      />
                    ))}
                    {(!membershipNfts || !membershipNfts.length) && (
                      <Grid item xs={12}>
                        <SendMembershipButton
                          walletAddress={walletAddress}
                          fetchMembershipNft={fetchMembershipNft}
                          fetchCustomer={fetchCustomer}
                          status={status}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    border: 1,
                    p: 3,
                    borderColor: "rgba(0, 0, 0, 0.26)",
                    borderRadius: 3,
                    m: 5,
                  }}
                >
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <Box
                        sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}
                      >
                        NFTメダル
                      </Box>
                    </Grid>
                    {medalNfts?.map((medalNft, index) => (
                      <MedalNft
                        medalNft={medalNft}
                        key={`medal-${medalNft.contract_address}-${medalNft.token_id}`}
                      />
                    ))}
                    {(membershipNfts || membershipNfts.length) && (
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            width: 190,
                            p: 3,
                            border: 2,
                            borderRadius: 5,
                            display: "block",
                            margin: "auto",
                            my: 1,
                            "&:hover": {
                              border: 2,
                            },
                          }}
                          onClick={mintNftMedal}
                          disabled={!signer || status !== "MEMBERSHIP"}
                        >
                          NFTメダルを送付する
                        </Button>
                        {!signer && (
                          <Typography
                            sx={{
                              color: "red",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            TOPページでウォレット接続してください
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Detail;
