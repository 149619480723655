/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:622a7563-e3ee-410e-ba9a-1f21462af3e0",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_LHOYWEu4Q",
    "aws_user_pools_web_client_id": "13mjp0uk7r71pvqelq4iln1e7f",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://catb63mrwnhtxkzluu7qjsmjza.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "saunoaAdminRestApi",
            "endpoint": "https://7bm1o599r5.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "saunoaMetadataApi",
            "endpoint": "https://tt1tw9tnp2.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "saunoaadminde2cb28bc6874bef95994569ab5944f7152722-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
