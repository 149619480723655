/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      visits {
        items {
          id
          createdAt
          updatedAt
          customerVisitsId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      visits {
        items {
          id
          createdAt
          updatedAt
          customerVisitsId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      walletAddress
      name
      email
      visits {
        items {
          id
          createdAt
          updatedAt
          customerVisitsId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const createVisit = /* GraphQL */ `
  mutation CreateVisit(
    $input: CreateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    createVisit(input: $input, condition: $condition) {
      id
      customer {
        walletAddress
        name
        email
        visits {
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      customerVisitsId
    }
  }
`;
export const updateVisit = /* GraphQL */ `
  mutation UpdateVisit(
    $input: UpdateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    updateVisit(input: $input, condition: $condition) {
      id
      customer {
        walletAddress
        name
        email
        visits {
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      customerVisitsId
    }
  }
`;
export const deleteVisit = /* GraphQL */ `
  mutation DeleteVisit(
    $input: DeleteVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    deleteVisit(input: $input, condition: $condition) {
      id
      customer {
        walletAddress
        name
        email
        visits {
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      customerVisitsId
    }
  }
`;
export const createSaunoaNftMedal = /* GraphQL */ `
  mutation CreateSaunoaNftMedal(
    $input: CreateSaunoaNftMedalInput!
    $condition: ModelSaunoaNftMedalConditionInput
  ) {
    createSaunoaNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      artist
      artTitle
      productionDate
      artistPage
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateSaunoaNftMedal = /* GraphQL */ `
  mutation UpdateSaunoaNftMedal(
    $input: UpdateSaunoaNftMedalInput!
    $condition: ModelSaunoaNftMedalConditionInput
  ) {
    updateSaunoaNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      artist
      artTitle
      productionDate
      artistPage
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaunoaNftMedal = /* GraphQL */ `
  mutation DeleteSaunoaNftMedal(
    $input: DeleteSaunoaNftMedalInput!
    $condition: ModelSaunoaNftMedalConditionInput
  ) {
    deleteSaunoaNftMedal(input: $input, condition: $condition) {
      id
      name
      description
      artist
      artTitle
      productionDate
      artistPage
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const createSaunoaNftThreshold = /* GraphQL */ `
  mutation CreateSaunoaNftThreshold(
    $input: CreateSaunoaNftThresholdInput!
    $condition: ModelSaunoaNftThresholdConditionInput
  ) {
    createSaunoaNftThreshold(input: $input, condition: $condition) {
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const updateSaunoaNftThreshold = /* GraphQL */ `
  mutation UpdateSaunoaNftThreshold(
    $input: UpdateSaunoaNftThresholdInput!
    $condition: ModelSaunoaNftThresholdConditionInput
  ) {
    updateSaunoaNftThreshold(input: $input, condition: $condition) {
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaunoaNftThreshold = /* GraphQL */ `
  mutation DeleteSaunoaNftThreshold(
    $input: DeleteSaunoaNftThresholdInput!
    $condition: ModelSaunoaNftThresholdConditionInput
  ) {
    deleteSaunoaNftThreshold(input: $input, condition: $condition) {
      threshold
      createdAt
      updatedAt
    }
  }
`;
