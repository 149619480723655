import { Wallet } from "ethers";
import WalletInfo from "../components/WalletInfo";
import MemberList from "../components/MemberList";
import NftMedal from "../components/NftMedal";

const MintMedal = () => {
  return (
    <>
      <NftMedal />
    </>
  );
};

export default MintMedal;
