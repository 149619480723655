import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { Box, Button, Grid, Divider, CircularProgress } from "@mui/material";

const Threshold = ({ threshold, fetchThresholds }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteThreshold = async (threshold) => {
    await API.graphql({
      query: mutations.deleteSaunoaNftThreshold,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          threshold: threshold,
        },
      },
    });
    await fetchThresholds();
  };

  const deleteThresholdHandler = async (threshold) => {
    setIsLoading(true);
    await deleteThreshold(threshold);
    setIsLoading(false);
  };

  return (
    <>
      <Grid item xs={6}>
        <Box sx={{ textAlign: "center" }}>{threshold}</Box>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="text"
          sx={{
            color: "red",
            display: "block",
            margin: "auto",
          }}
          onClick={() => deleteThresholdHandler(threshold)}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size="1.5rem" sx={{ color: "red" }} />
          ) : (
            "削除する"
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 1 }} />
      </Grid>
    </>
  );
};

export default Threshold;
