import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";

const SendMembershipButton = ({
  walletAddress,
  fetchMembershipNft,
  fetchCustomer,
  status,
}) => {
  const { signer, membershipMint } = useWeb3Provider();
  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = async () => {
    await API.graphql({
      query: mutations.updateCustomer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          walletAddress: walletAddress,
          status: "MEMBERSHIP",
        },
      },
    });
    await fetchCustomer();
    await fetchMembershipNft();
  };

  const mintMembershipNft = async () => {
    setIsLoading(true);
    await membershipMint(walletAddress);
    await updateStatus();
    setIsLoading(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: 190,
          p: 3,
          border: 2,
          borderRadius: 5,
          display: "block",
          margin: "auto",
          my: 1,
          "&:hover": {
            border: 2,
          },
        }}
        disabled={isLoading || !signer || status !== "CLAIM"}
        onClick={mintMembershipNft}
      >
        {isLoading ? <CircularProgress /> : "NFT会員券を送付する"}
      </Button>
      {!signer && (
        <Typography
          sx={{
            color: "red",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          TOPページでウォレット接続してください
        </Typography>
      )}
    </>
  );
};

export default SendMembershipButton;
