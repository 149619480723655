import React, { useContext, useEffect, useState, createContext } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import membershipAbi from "../public/membershipAbi.json";

const Web3Context = createContext({
  provider: null,
  signer: null,
  connectedWalletAddress: null,
  connect: async () => {},
  disconnect: async () => {},
  getWalletAddress: async () => {},
  membershipMint: async () => {},
  membershipBurn: async () => {},
  medalMint: async () => {},
  getMembershipOwner: async () => {},
});

export const useWeb3Provider = () => {
  return useContext(Web3Context);
};

const Web3Provider = ({ children }) => {
  const [web3Modal, setWeb3Modal] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [connectedWalletAddress, setConnectedWalletAddress] = useState(null);
  const [isInitializing, setIsInitializing] = useState(false);

  const initWeb3Provider = async () => {
    const providerOptions = {};
    const web3Modal = new Web3Modal({
      network: process.env.REACT_APP_NETWORK, // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });
    setWeb3Modal(web3Modal);
    const network =
      process.env.REACT_APP_NETWORK === "mainet" ? "homestead" : "goerli";
    const provider = ethers.getDefaultProvider(network);
    setProvider(provider);
  };

  const connect = async () => {
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
    setProvider(provider);
    setSigner(signer);
  };
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    const network =
      process.env.REACT_APP_NETWORK === "mainet" ? "homestead" : "goerli";
    const provider = ethers.getDefaultProvider(network);
    setProvider(provider);
    setSigner(null);
    setConnectedWalletAddress(null);
  };
  const updateWalletAddress = async () => {
    const account = await getWalletAddress();
    setConnectedWalletAddress(account);
  };

  const getWalletAddress = async () => {
    return await signer.getAddress();
  };
  const membershipMint = async (toAddress) => {
    const contract = new ethers.Contract(
      process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT,
      membershipAbi,
      signer
    );
    const nftTxn = await contract.mint(toAddress);
    await nftTxn.wait();
  };
  const medalMint = async (toAddress, tokenUri) => {
    const contract = new ethers.Contract(
      process.env.REACT_APP_MEDAL_NFT_CONTRACT,
      ["function mint(address, string memory)"],
      signer
    );
    const nftTxn = await contract.mint(toAddress, tokenUri);
    await nftTxn.wait();
  };
  const membershipBurn = async (tokenId) => {
    const contract = new ethers.Contract(
      process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT,
      membershipAbi,
      signer
    );
    const nftTxn = await contract.burn(tokenId);
    await nftTxn.wait();
  };
  const getMembershipOwner = async (tokenId) => {
    const contract = new ethers.Contract(
      process.env.REACT_APP_MEMBERSHIP_NFT_CONTRACT,
      membershipAbi,
      provider
    );
    return await contract.ownerOf(tokenId);
  };

  // web3auth初期化
  useEffect(() => {
    initWeb3Provider();
  }, []);

  useEffect(() => {
    // connect automatically and without a popup if user is already connected
    if (web3Modal && web3Modal.cachedProvider) {
      connect();
    }
  }, [web3Modal]);

  // wallet addressのセット
  useEffect(() => {
    if (signer) {
      updateWalletAddress();
    }
  }, [signer]);

  const contextProvider = {
    provider,
    signer,
    connectedWalletAddress,
    connect,
    disconnect,
    membershipMint,
    membershipBurn,
    medalMint,
    getMembershipOwner,
    isInitializing,
  };
  return (
    <Web3Context.Provider value={contextProvider}>
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;
