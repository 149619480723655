import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "@aws-amplify/api";
import { v4 as uuidv4 } from "uuid";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useWeb3Provider } from "../hooks/Web3Provider";

const Visit = () => {
  const navigate = useNavigate();
  const { getMembershipOwner } = useWeb3Provider();
  const [inputMembershipNo, setInputMembershipNo] = useState("");
  const [customer, setCustomer] = useState([]);

  const clickEditBtn = (walletAddress) => {
    navigate(`/detail/${walletAddress}`);
  };

  const convertStatus = (status) => {
    if (status === "CLAIM") {
      return "会員券請求中";
    } else if (status === "MEMBERSHIP") {
      return "会員";
    } else if (status === "WITHDRAWAL") {
      return "退会申請中";
    } else if (status === "BURN") {
      return "退会";
    }
  };

  const inputMembershipNoHandler = async (e) => {
    setInputMembershipNo(e.target.value);
    try {
      const ownerAddress = await getMembershipOwner(e.target.value);
      await fetchCustomer(ownerAddress);
    } catch (error) {
      setCustomer([]);
    }
  };

  const visitButtonHandler = async () => {
    try {
      await addVisit(customer[0].walletAddress);
      await fetchCustomer(customer[0].walletAddress);
    } catch (error) {
      console.log(error);
    }
    console.log("a");
  };

  const fetchCustomer = async (ownerAddress) => {
    const result = await API.graphql({
      query: queries.getCustomer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        walletAddress: ownerAddress,
        visitsLimit: 999999999,
      },
    });
    const customer = result.data;
    if (customer.getCustomer) {
      setCustomer([
        {
          walletAddress: customer.getCustomer.walletAddress,
          name: customer.getCustomer.name,
          email: customer.getCustomer.email,
          visit: customer.getCustomer.visits.items.length,
          status: convertStatus(customer.getCustomer.status),
        },
      ]);
    } else {
      setCustomer([]);
    }
  };

  const addVisit = async (ownerAddress) => {
    console.log(ownerAddress);
    const createdAt = new Date().toISOString();
    const result = await API.graphql({
      query: mutations.createVisit,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id: uuidv4(),
          customerVisitsId: ownerAddress,
          createdAt: createdAt,
          updatedAt: createdAt,
        },
      },
    });
    return result.data;
  };

  const cols = [
    {
      field: "walletAddress",
      headerName: "Wallet Address",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: "名前",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "visit",
      headerName: "来店回数",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "ステータス",
      minWidth: 100,
      flex: 1,
    }, // 詳細ボタン
    {
      field: "editBtn",
      headerName: "詳細",
      sortable: false,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{ borderRadius: 6 }}
          onClick={() => clickEditBtn(params.id)}
        >
          詳細
        </Button>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Box
            sx={{
              width: "100%",
              border: 1,
              p: 3,
              borderColor: "rgba(0, 0, 0, 0.26)",
              borderRadius: 3,
              mb: 3,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                  来店管理
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ m: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">会員No.</InputAdornment>
                    ),
                  }}
                  onChange={inputMembershipNoHandler}
                />
                <Button
                  variant="contained"
                  disabled={!customer.length}
                  sx={{ m: 1, mx: 3, px: 5, py: 2, borderRadius: 6 }}
                  onClick={visitButtonHandler}
                >
                  来店ボタン
                </Button>
                <DataGrid
                  columns={cols}
                  rows={customer}
                  autoHeight
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  hideFooter
                  getRowId={(row) => row.walletAddress}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Visit;
