import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as mutations from "../graphql/mutations";
import {
  Box,
  Button,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ja from "date-fns/locale/ja";

const CustomerDetail = ({
  walletAddress,
  name,
  email,
  status,
  visits,
  createdAt,
  updatedAt,
  fetchCustomer,
}) => {
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const changeStatusHandler = (event, value) => {
    setSelectedStatus(value);
  };
  const updateStatus = async () => {
    await API.graphql({
      query: mutations.updateCustomer,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          walletAddress: walletAddress,
          status: selectedStatus,
        },
      },
    });
    await fetchCustomer();
    setIsEditStatus(false);
  };
  const convertStatus = (status) => {
    if (status === "CLAIM") {
      return "会員券請求中";
    } else if (status === "MEMBERSHIP") {
      return "会員";
    } else if (status === "WITHDRAWAL") {
      return "退会申請中";
    } else if (status === "BURN") {
      return "退会";
    }
  };
  const formatDate = (date) => {
    if (!date) {
      return;
    }
    return format(
      utcToZonedTime(parseISO(date), "Asia/Tokyo"),
      "yyyy/MM/dd (E) HH:mm",
      { locale: ja }
    );
  };

  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box
          sx={{
            width: "100%",
            border: 1,
            p: 3,
            borderColor: "rgba(0, 0, 0, 0.26)",
            borderRadius: 3,
            mb: 3,
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                詳細情報
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                Wallet Address
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{walletAddress}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>名前</Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{name}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                メールアドレス
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{email}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                来店回数
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{visits?.length}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
                ステータス
              </Box>
            </Grid>
            <Grid item xs={5}>
              {isEditStatus ? (
                <>
                  <FormControl>
                    <RadioGroup
                      defaultValue={status}
                      onChange={changeStatusHandler}
                    >
                      <FormControlLabel
                        value="CLAIM"
                        control={<Radio />}
                        label="会員券請求中"
                      />
                      <FormControlLabel
                        value="MEMBERSHIP"
                        control={<Radio />}
                        label="会員"
                      />
                      <FormControlLabel
                        value="WITHDRAWAL"
                        control={<Radio />}
                        label="退会申請中"
                      />
                      <FormControlLabel
                        value="BURN"
                        control={<Radio />}
                        label="退会"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              ) : (
                <>
                  <Box>{convertStatus(status)}</Box>
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              {isEditStatus ? (
                <>
                  <Box>
                    <Button variant="text" onClick={() => updateStatus()}>
                      更新する
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        color: "red",
                      }}
                      onClick={() => {
                        setIsEditStatus(false);
                        setSelectedStatus(status);
                      }}
                    >
                      キャンセル
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Button
                      variant="text"
                      sx={{
                        display: "block",
                        margin: "auto",
                      }}
                      onClick={() => setIsEditStatus(true)}
                    >
                      手動で更新する
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>作成日</Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{formatDate(createdAt)}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold" }}>更新日</Box>
            </Grid>
            <Grid item xs={8}>
              <Box>{formatDate(updatedAt)}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CustomerDetail;
