import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useWeb3Provider } from "../hooks/Web3Provider";

const OpenEtherscan = ({ contract }) => {
  const connectHandler = async () => {
    window.open(`https://etherscan.io/address/${contract}`, "_blank");
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: 180,
          p: 1,
          border: 2,
          borderRadius: 5,
          "&:hover": {
            border: 2,
          },
        }}
        onClick={connectHandler}
      >
        Etherscanで確認する
      </Button>
    </>
  );
};

export default OpenEtherscan;
