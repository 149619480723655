import { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { Button, CircularProgress, Typography } from "@mui/material";

const VisitHistoryDeleteButton = ({ visitId, fetchCustomer }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteVisitHistory = async () => {
    await API.graphql({
      query: mutations.deleteVisit,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id: visitId,
        },
      },
    });
    await fetchCustomer();
  };

  const deleteVisitHistoryHandler = async () => {
    setIsLoading(true);
    await deleteVisitHistory();
    setIsLoading(false);
  };
  return (
    <>
      <Button
        variant="text"
        sx={{
          color: "red",
          display: "block",
          margin: "auto",
        }}
        onClick={deleteVisitHistoryHandler}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size="1.5rem" sx={{ color: "red" }} />
        ) : (
          "削除する"
        )}
      </Button>
    </>
  );
};

export default VisitHistoryDeleteButton;
