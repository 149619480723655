/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($walletAddress: ID!, $visitsLimit: Int) {
    getCustomer(walletAddress: $walletAddress) {
      walletAddress
      name
      email
      visits(limit: $visitsLimit) {
        items {
          id
          createdAt
          updatedAt
          customerVisitsId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $walletAddress: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $visitsLimit: Int
  ) {
    listCustomers(
      walletAddress: $walletAddress
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        walletAddress
        name
        email
        visits(limit: $visitsLimit) {
          items {
            id
            createdAt
            updatedAt
            customerVisitsId
          }
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVisit = /* GraphQL */ `
  query GetVisit($id: ID!) {
    getVisit(id: $id) {
      id
      customer {
        walletAddress
        name
        email
        visits {
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      customerVisitsId
    }
  }
`;
export const listVisits = /* GraphQL */ `
  query ListVisits(
    $filter: ModelVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer {
          walletAddress
          name
          email
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        customerVisitsId
      }
      nextToken
    }
  }
`;
export const getSaunoaNftMedal = /* GraphQL */ `
  query GetSaunoaNftMedal($id: ID!) {
    getSaunoaNftMedal(id: $id) {
      id
      name
      description
      artist
      artTitle
      productionDate
      artistPage
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const listSaunoaNftMedals = /* GraphQL */ `
  query ListSaunoaNftMedals(
    $filter: ModelSaunoaNftMedalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaunoaNftMedals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        artist
        artTitle
        productionDate
        artistPage
        imageUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaunoaNftThreshold = /* GraphQL */ `
  query GetSaunoaNftThreshold($threshold: Int!) {
    getSaunoaNftThreshold(threshold: $threshold) {
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const listSaunoaNftThresholds = /* GraphQL */ `
  query ListSaunoaNftThresholds(
    $threshold: Int
    $filter: ModelSaunoaNftThresholdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSaunoaNftThresholds(
      threshold: $threshold
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        threshold
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
