import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { Box, Button, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ja from "date-fns/locale/ja";

const MemberList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const clickEditBtn = (walletAddress) => {
    navigate(`/detail/${walletAddress}`);
  };

  const convertStatus = (status) => {
    if (status === "CLAIM") {
      return "会員券請求中";
    } else if (status === "MEMBERSHIP") {
      return "会員";
    } else if (status === "WITHDRAWAL") {
      return "退会申請中";
    } else if (status === "BURN") {
      return "退会";
    }
  };
  const formatDate = (date) => {
    if (!date) {
      return;
    }
    return format(
      utcToZonedTime(parseISO(date), "Asia/Tokyo"),
      "yyyy/MM/dd (E) HH:mm",
      { locale: ja }
    );
  };

  const fetchCustomer = async () => {
    const result = await API.graphql({
      query: queries.listCustomers,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        limit: 999999999,
        visitsLimit: 999999999,
      },
    });
    console.log(result.data);
    const rows = result.data.listCustomers.items.map((item) => {
      return {
        walletAddress: item.walletAddress,
        name: item.name,
        email: item.email,
        visit: item.visits.items.length,
        status: convertStatus(item.status),
        createdAt: formatDate(item.createdAt),
        updatedAt: formatDate(item.updatedAt),
      };
    });
    setRows(rows);
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const cols = [
    {
      field: "walletAddress",
      headerName: "Wallet Address",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: "名前",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "visit",
      headerName: "来店回数",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "ステータス",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "作成日",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "更新日",
      minWidth: 100,
      flex: 1,
    }, // 詳細ボタン
    {
      field: "editBtn",
      headerName: "詳細",
      sortable: false,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{ borderRadius: 6 }}
          onClick={() => clickEditBtn(params.id)}
        >
          詳細
        </Button>
      ),
    },
  ];
  return (
    <>
      <Box sx={{ m: 5 }}>
        <Box sx={{ m: 5 }}>
          <Box
            sx={{
              width: "100%",
              border: 1,
              p: 3,
              borderColor: "rgba(0, 0, 0, 0.26)",
              borderRadius: 3,
              mb: 3,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
                  会員情報
                </Box>
              </Grid>

              <Grid item xs={12}>
                <DataGrid
                  columns={cols}
                  rows={rows}
                  autoHeight
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  getRowId={(row) => row.walletAddress}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MemberList;
