import { Wallet } from "ethers";
import WalletInfo from "../components/WalletInfo";
import MemberList from "../components/MemberList";
import Visit from "../components/Visit";

const Home = () => {
  return (
    <>
      <WalletInfo />
      <Visit />
      <MemberList />
    </>
  );
};

export default Home;
